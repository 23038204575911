/* Color Theme Swatches in Hex */
$greenLight1: #011C26;
$greenLight2: #025E73;
$greenLight3: #037F8C;
$greenLight4: #03A6A6;
$skinColor: #D9B6A3;
$linkHover: #fc3c3c;
$grey: #333;
$lightGrey: #E9EAEF;

@mixin all-fadeinout() {
  -webkit-transition: all 0.2s ease-in, all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in, all 0.2s ease-in;
  -o-transition: all 0.2s ease-in, all 0.2s ease-in;
  transition: all 0.2s ease-in, all 0.2s ease-in;
}

@mixin no-all-transition() {
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: all 0 ease-in;
  transition: none;
}

button {
  border: none !important;
  color: #333;
}

a {
  color: $grey;
  @include all-fadeinout();

  &:hover {
    color: $linkHover !important;
  }
}

#sites-navbar {
  border-top: 1px solid #ddd;
}