footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 30px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(221, 221, 221);
  text-align: center;
}