#banner {
  // background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5));
  background-size: cover;
  background-position: 50%;
  padding: 120px 0;
  color: #fff;
  text-align: center;

  h1 {
    color: #f8b500;
  }

  p {
    text-align: center;
    font-size: 20px;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}