@font-face {
  font-family: 'Barlow Black';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-Black.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow Regular';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow BlackItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-BlackItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow Bold';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-Bold.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow BoldItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-BoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow ExtraBold';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-ExtraBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow ExtraBoldItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-ExtraBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow ExtraLight';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-ExtraLight.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow ExtraLightItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-ExtraLightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow Italic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-Italic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow Light';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-Light.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow LightItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-LightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow Medium';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-Medium.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow MediumItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-MediumItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow SemiBold';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow SemiBoldItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-SemiBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow Thin';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-Thin.ttf') format("truetype");
}

@font-face {
  font-family: 'Barlow ThinItalic';
  src: url('fonts/Barlow_Condensed/BarlowCondensed-ThinItalic.ttf') format("truetype");
}

* {
  font-family: "Barlow Regular";
}
